import axiosInstance from "./Api";

export default {
  async SignIn(payload) {
    let response = await axiosInstance().post(`/auth/login`, payload);
    return response;
  },

  async RestLink(payload) {
    let response = await axiosInstance().post(`/auth/reset_link`, payload);
    return response;
  },

  async UpdatePassword(payload) {
    let response = await axiosInstance().post(`/auth/update_password`, payload);
    return response;
  },

  async updateForgotPassword(payload) {
    let response = await axiosInstance().post(`/auth/updateForgotPassword`, payload);
    return response;
  },

  async VerifyToken(payload) {
    let response = await axiosInstance().post(`/auth/verify_token`, payload);
    return response;
  },

  async RegisterUser(payload) {
    let response = await axiosInstance().post(`/auth/register`, payload);
    return response;
  },

  async CreateProduct(payload) {
    let response = await axiosInstance().post("/product", payload);
    console.log(response);
    return response;
  },

  async VerifyAccount(payload) {
    let response = await axiosInstance().post("/auth/verify_account", payload);
    console.log(response);
    return response;
  },

  async VerifyCPToken(payload) {
    let response = await axiosInstance().post(`/auth/verify_cp_token`, payload);
    return response;
  },

  async createPassword(payload) {
    let response = await axiosInstance().post(`/auth/create_password`, payload);
    return response;
  },

  async resendVerivicaftionLink(payload) {
    let response = await axiosInstance().post(`/auth/resendVerivicaftionLink`, payload);
    return response;
  },

  async checkAccountVerified(payload) {
    let response = await axiosInstance().post(`/auth/checkVerifiedAccount`, payload);
    return response;
  },

  async SubscribeUser(payload) {
    let response = await axiosInstance().post(`/auth/subscribe`, payload);
    return response;
  },

  async wpLogin(url, payload) {
    /* let response = await axiosInstance().post(`/auth/login`, payload);
    return response; */
    let response = await axios({
      method: "post",
      url: url + "/my-account",
      data: payload,
      headers: { "Content-Type": "multipart/form-data" },
    });
    return response;
  },

};
